import axios from 'axios'
import { useRouter } from 'next-translate-routes/router'
import React, { useEffect, useState } from 'react'

import {
  Filter,
  FindologicProduct,
  FindologicSearch,
  Item,
  TextSuggestion,
} from '../models/findologic'
import { Skill } from '../models/findologic-lisa'

export interface SelectedAttribute {
  type: string
  value: string
}

export interface SearchContext {
  searchQuery: string
  setSearchQuery: any
  items: Item[]
  products: FindologicProduct[]
  textSuggestions: TextSuggestion[]
  setHasFocus
  hasFocus: boolean
  recipes: Item[]
  sets: Item[]
  filter: Filter[]
  toggleAttribute: any
  attributes: SelectedAttribute[]
  lisaCategories: Skill
  lisaFilters: Skill
  sortOrder: string[]
  getCategoryOrder: (catSlug: string) => Promise<void>
  toggleRecipeAttribute: any
  recipeAttributes: SelectedAttribute[]
  allRecipeFilter: Filter[]
  shopKey: string
}

const BASE_URL = `https://api.batteryincluded.io/api/v1/collections/`

const API_KEY = 'ONTklp0c3tpSDEb28HhFQ2A1bB6BaROZ'

export const useSearchContext = (): SearchContext => {
  const [searchQuery, setSearchQuery] = useState<string>('')

  const [items, setItems] = useState<Item[]>([])

  const [recipes, setRecipes] = useState<Item[]>([])

  const [sets, setSets] = useState<Item[]>([])

  const [hasFocus, setHasFocus] = useState<boolean>(false)

  const [products, setProducts] = useState<any[]>([])

  const [textSuggestions, setTextSuggestions] = useState<any[]>([])

  const [filter, setFilter] = useState<Filter[]>([])

  const [allFilter, setAllFilter] = useState<Filter[]>([])

  const [allRecipeFilter, setAllRecipeFilter] = useState<Filter[]>([])

  const [attributes, setAttributes] = useState<SelectedAttribute[]>([])

  const [recipeAttributes, setRecipeAttributes] = useState<SelectedAttribute[]>(
    []
  )

  const [lisaCategories, setLisaCategories] = useState<Skill>(undefined)

  const [lisaFilters, setLisaFilters] = useState<Skill>(undefined)

  const [sortOrder, setSortOrder] = useState<string[]>(undefined)

  const router = useRouter()

  const [shopKey, setShopKey] = useState<string>(undefined)

  useEffect(() => {
    fetch(
      `https://pim.stayspiced.com/findologic/getkey?language=${router.locale}&shopId=${process.env.NEXT_PUBLIC_SHOPWARE_ID}`
    )
      .then((data) => data.json())
      .then((data) => setShopKey(data.key))
      .catch((err) => console.log(err))
  }, [])

  useEffect(() => {
    if (searchQuery.length > 2) {
      getAutoSuggestions().catch((err) => console.log(err))
    }
  }, [searchQuery, attributes, recipeAttributes])

  useEffect(() => {
    if (textSuggestions && textSuggestions[0]) {
      getSearchResults().catch((err) => console.log(err))

      getRecipeResult().catch((err) => console.log(err))

      getSetsResults().catch((err) => console.log(err))

      // getLisaSuggestions().catch((err) => console.log(err))
    }
  }, [textSuggestions])

  useEffect(() => {
    if (searchQuery) {
      setAttributes([])

      setLisaCategories(undefined)
    }
  }, [searchQuery])

  const transformedAttributes = () =>
    attributes.map((attr) => `f[${attr.type}][]=${attr.value}`).join('&')

  const transformedRecipeAttributes = () =>
    recipeAttributes.map((attr) => `f[${attr.type}][]=${attr.value}`).join('&')

  function toggleAttribute(attribute: SelectedAttribute) {
    const foundIndex = attributes.findIndex(
      (attr) => attr.type === attribute.type && attr.value === attribute.value
    )

    if (foundIndex !== -1) {
      attributes.splice(foundIndex, 1)

      setAttributes([...attributes])
    } else {
      setAttributes([...attributes, attribute])
    }
  }

  function toggleRecipeAttribute(attribute: SelectedAttribute) {
    const foundIndex = recipeAttributes.findIndex(
      (attr) => attr.type === attribute.type && attr.value === attribute.value
    )

    if (foundIndex !== -1) {
      recipeAttributes.splice(foundIndex, 1)

      setRecipeAttributes([...attributes])
    } else {
      setRecipeAttributes([...attributes, attribute])
    }
  }

  async function getAutoSuggestions() {
    try {
      const autoSuggestResults = await axios.get<any>(
        `${BASE_URL}customer.stayspiced.com/documents/suggest?q=${searchQuery}`,
        {
          headers: {
            'X-BI-API-KEY': API_KEY,
          },
        }
      )

      if (autoSuggestResults.data) {
        setTextSuggestions(
          autoSuggestResults.data.find((x) => x.kind === 'query-completion')
            ?.hits
        )
        setProducts(
          autoSuggestResults.data.find((x) => x.kind === 'document')?.hits
        )
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (lisaFilters && filter) {
      const foundFiltersFromLisa = Object.keys(
        (lisaFilters.config as any)?.filters
      )

      setFilter([
        ...allFilter.filter((filt) => foundFiltersFromLisa.includes(filt.name)),
      ])
    }
  }, [lisaFilters, allFilter])

  async function getSearchResults() {
    try {
      const searchResults = await axios.get<any>(
        `${BASE_URL}customer.stayspiced.com/documents/browse?q=${searchQuery}&f[type][]=product&f[type][]=set${
          attributes.length > 0 ? `&${transformedAttributes()}` : ''
        }`,
        {
          headers: {
            'X-BI-API-KEY': API_KEY,
          },
        }
      )

      if (searchResults.data) {
        setItems(searchResults.data.hits)

        // setAllFilter(searchResults.data.result.filters.main)
      }
    } catch (err) {
      console.log(err)
    }
  }

  async function getRecipeResult() {
    try {
      const searchResults = await axios.get<any>(
        `${BASE_URL}customer.stayspiced.com/documents/browse?q=${searchQuery}&f[type][]=recipe${
          attributes.length > 0 ? `&${transformedAttributes()}` : ''
        }`,
        {
          headers: {
            'X-BI-API-KEY': API_KEY,
          },
        }
      )

      if (searchResults.data) {
        setRecipes(searchResults.data.hits)
      }
    } catch (err) {
      console.log(err)
    }
  }

  async function getSetsResults() {
    try {
      const searchResults = await axios.get<any>(
        `${BASE_URL}customer.stayspiced.com/documents/browse?q=${searchQuery}&f[type][]=set${
          attributes.length > 0 ? `&${transformedAttributes()}` : ''
        }`,
        {
          headers: {
            'X-BI-API-KEY': API_KEY,
          },
        }
      )

      if (searchResults.data) {
        setSets(searchResults.data.hits)
      }
    } catch (err) {
      console.log(err)
    }
  }

  async function getCategoryOrder(catSlug: string) {
    try {
      const searchResults = await axios.get<FindologicSearch>(
        `${BASE_URL}/www.spiceworld.at/index.php?shopkey=${shopKey}&outputAdapter=JSON_1.0&attrib[cat][]=Pfeffer`
      )

      if (searchResults.data) {
        setSortOrder(searchResults.data.result.items.map((p) => p.id))
      }
    } catch (err) {
      console.log(err)
    }
  }

  return {
    searchQuery,
    setSearchQuery,
    items,
    products,
    textSuggestions,
    setHasFocus,
    hasFocus,
    recipes,
    sets,
    filter,
    toggleAttribute,
    attributes,
    lisaCategories,
    lisaFilters,
    sortOrder,
    getCategoryOrder,
    toggleRecipeAttribute,
    recipeAttributes,
    allRecipeFilter,
    shopKey,
  }
}

export const searchContext = React.createContext<SearchContext>(undefined)
